import jQuery from './jquery-1.12.4.min.js';
import Cookies from 'js-cookie';
import objectFitImages from 'object-fit-images';


// mobile menu
jQuery(function($) {
  var $target=$("header nav ul li.mob-only").last();
  $target.addClass("has-child");
  $(".my-egov>ul").clone().appendTo($target);
});

// clicks on common elements
jQuery(function($) {
  $(".lang .ll-active a").click(function(e) {
    e.preventDefault();
    $(this).closest(".lang").toggleClass("open");
  });
  $(".my-egov>a:first-child").click(function(e) {
    e.preventDefault();
    $(this).closest(".my-egov").toggleClass("open");
  });
  $(".foot-top a").click(function(e) {
    e.preventDefault();
    $("html,body").animate({scrollTop: 0}, 300);
  });
  $(".set-handle, .settings-box a.close, footer .nav .emph a").click(function(e) {
    e.preventDefault();
    $("header").toggleClass("setopen");
    setTimeout(function() {
      $("header .nav").animate({scrollTop: $("header .nav")[0].scrollHeight-$("header .nav")[0].offsetHeight}, 300);
    }, 16);
    $("html,body").animate({scrollTop: 0});
  });
  
  $(".lang, .my-egov").click(function(e) {
    e.stopPropagation();
  });
  $(document).click(function(e) {
    $(".lang").removeClass("open");
    $(".my-egov").removeClass("open");
  });
});

// theme switcher
(function($) {
  var fontLis=$(".settings-box .font li");
  var themeLis=$(".settings-box .theme li");
  var setThemeCookie=function() {
    var fontLiIndex=fontLis.index(fontLis.filter(".active"));
    var themeLiIndex=themeLis.index(themeLis.filter(".active"));
    if (fontLiIndex==-1)
      fontLiIndex=1;
    if (themeLiIndex==-1)
      themeLiIndex=0;
    var theme=fontLiIndex+":"+themeLiIndex;
    fontLis.removeClass("active").eq(fontLiIndex).addClass("active");
    themeLis.removeClass("active").eq(themeLiIndex).addClass("active");
    Cookies.set("euprava-theme", theme, {path: "/"});
  }
  fontLis.find("a").click(function(e) {
    e.preventDefault();
    var index=fontLis.find("a").index(this);
    fontLis.removeClass("active").eq(index).addClass("active");
    $("html").removeClass("large small");
    if (index==0)
      $("html").addClass("small");
    if (index==2)
      $("html").addClass("large");
    setThemeCookie();
  });
  themeLis.find("a").click(function(e) {
    e.preventDefault();
    var index=themeLis.find("a").index(this);
    themeLis.removeClass("active").eq(index).addClass("active");
    var styleSheets=$("[data-theme]");
    styleSheets.each(function() {
      if (!$(this).attr("data-href"))
        $(this).attr("data-href", $(this).attr("href"));
      if ($(this).attr("data-theme")==index+"")
        $(this).attr("href", $(this).attr("data-href"));
      else
        setTimeout(()=>{
          $(this).removeAttr("href");
        }, 100);
    });
    setThemeCookie();
  });
  
  var themeCookie=(Cookies.get("euprava-theme") || "1:0").split(":");
  fontLis.eq(themeCookie[0]).find("a").click();
  themeLis.eq(themeCookie[1]).find("a").click();
})(jQuery);

// scroll animations
jQuery(function($) {
  var $window=$(window);
  var footTop=$(".foot-top");
  var header=$("header");
  $window.on("scroll.common", function(e) {
    var scrollTop=$window.scrollTop();
    if (scrollTop>=40)
      footTop.css("bottom", "0px");
    else
      footTop.css("bottom", scrollTop-40+"px");
    var footButton=$(".foot-button");
    if (scrollTop>=74)
      footButton.css("bottom", "0px");
    else
      footButton.css("bottom", scrollTop-74+"px");    
    if (scrollTop>=110 && window.innerWidth>990)
      header.addClass("scrolled");
    else
      header.removeClass("scrolled");
  }).trigger("scroll.common").on("resize.common", function() {
    $window.trigger("scroll.common");
  });
});


// head tabs
jQuery(function($) {
  var container=$(".head-tabs");
  var tabs=container.find(">ul>li");
  var links=tabs.find(">a");
  
  var activeTab=Cookies.get("active-tab");
  if (activeTab===undefined)
    activeTab=0;
  tabs.eq(activeTab).addClass("active");
  
  links.click(function(e) {
    activeTab=links.index(this);
    Cookies.set("active-tab", activeTab, {path: "/"});
  });
});


var convertToLatin=function(str) {
  str=(str || "")+"";
  str=str.toLowerCase();
  var map={
    "а":"a", "б":"b", "в":"v", "г":"g", "д":"d", "ђ":"dj", "е":"e", "ж":"z", 
    "з":"z", "и":"i", "ј":"j", "к":"k", "л":"l", "љ":"lj", "м":"m", "н":"n", 
    "њ":"nj", "о":"o", "п":"p", "р":"r", "с":"s", "т":"t", "ћ":"ć", "у":"u", 
    "ф":"f", "х":"h", "ц":"c", "ч":"c", "џ":"dz", "ш":"s",
    "š":"s", "đ":"dj", "č":"c", "ć":"c", "ž":"z" 
  };
  str=str.replace(/./g, (match)=>map[match] || match);
  return str;
}

// municipality updater
jQuery(function($) {
  $(document).click(function() {
    $(".muni").removeClass("help-open");
  });
  $(".muni>.help").click(function(e) {
    $(".muni").toggleClass("help-open");
    e.stopPropagation();
  });
  $(".muni>a").click(function(e) {
    $(".muni").toggleClass("open");
    e.stopPropagation();
    $(".muni-updater input").focus().val("").trigger("input");
  });
  $(".muni-updater").click(function(e) {
    e.stopPropagation();
  });
  $(document).click(function(e) {
    $(".muni").removeClass("open");
  });
  $(".muni-updater a.close").click(function(e) {
    e.preventDefault();
    $(".muni").toggleClass("open");
  });
  $(".muni-updater li a").click(function(e) {
    Cookies.set("active-muni", $(this).parent().attr("data-value"), {path: "/"});
    $(".muni .current").html($(this).html());
    location.reload();
  });
  $(".location-select form").submit(function(e) {
    e.preventDefault();
    var val=$(this).find("select").val();
    Cookies.set("active-muni", val, {path: "/"});
    $(".muni .current").html($(this).find("select option[value='"+val+"']").html());
    $(".form-popup").removeClass("visible");
    location.reload();
  });
  var currentMuni=Cookies.get("active-muni");
  var identity=Cookies.get("cms_ctck");
  if (!currentMuni && identity) {
    $(".location-select").parent().addClass("visible");
  }
  var currentMuniText="";
  $(".muni-updater li").each(function(i) {
    if (!currentMuni && i==0)
      currentMuniText=$(this).find("a").html();
    if (currentMuni==$(this).attr("data-value"))
      currentMuniText=$(this).find("a").html();
  });
  $(".muni .current").html(currentMuniText);
  
  $(".muni-updater input").on("input", function() {
    var value=jQuery(this).val();
    $(".muni-updater li").each(function() {
      if (!value || convertToLatin(jQuery(this).html()).indexOf(convertToLatin(value))!=-1) {
        jQuery(this).slideDown(300);
      } else {
        jQuery(this).slideUp(300);
      }
    });
  });
});

jQuery(function($) {
  var activeLine=$("<div>", {"class": "active-line"});
  var root=$(".nav nav");
  root.prepend(activeLine);
  var currentItem=$("header .nav nav>ul>li.active");
  var hoverItem=null;
  var updateCurrent=function(immediate) {
    var c=hoverItem || currentItem;
    if (!currentItem || !currentItem.length)
      immediate=true;
    if (!c || !c.length) {
      activeLine.hide();
      return;
    }
    var left=c.offset().left-root.offset().left;
    var width=c.innerWidth();
    activeLine.css({left: left, width: width, transition: immediate?"0s":".3s"});
  };
  updateCurrent(true);
  $(window).on("load", ()=>{updateCurrent(true)});
//   $(".nav nav>ul>li, .my-egov>a").hover(function(e) {
//     hoverItem=$(this);
//     updateCurrent();
//   }, function(e) {
//     hoverItem=null;
//     updateCurrent();
//   });
});

jQuery(function($) {
  $(".mobile-handle").click(function(e) {
    if (!$("header .current-user").length) {
      var loggedInButton=$(".my-egov .logged-in");
      var currentUser=$("<div />", {"class": "current-user"}).appendTo("header .nav");
      if (loggedInButton.length) {
        var currentUserName=$("<div />", {"class": "name", html: loggedInButton.html()}).appendTo(currentUser);
        var currentUserButton=$("<a />", {href: loggedInButton.attr("data-logout-url"), "class": "btn modify", role: "button", html: "<span>"+loggedInButton.attr("data-logout-text")+"</span><span></span></a>"}).appendTo(currentUser);
      }
    }
    e.preventDefault();
    $("header").toggleClass("open");
    if (!$("header").hasClass("open"))
      $("header").removeClass("setopen");
  });
});

var $notif=jQuery("#notification");
// jQuery(window).on("resize.notif", function() {
//   if ($notif.length) {
//     var top=Math.max(0, $notif.innerHeight()-jQuery(window).scrollTop());
//     jQuery("header .mobile-handle").css("top", 15+top);
//   }
// }).trigger("resize.notif").on("scroll.notif", function() {
//   jQuery(window).trigger("resize.notif");
// }).on("load.notif", function() {
//   jQuery(window).trigger("resize.notif");
// });

jQuery(function($) {
  $("#notification .close").click(function(e) {
    e.preventDefault();
    $("#notification").addClass("closed");
//     $("header .mobile-handle").css("top", 15);
  });
});

jQuery(function($) {
  $("body > header nav > ul > li > ul > li.has-child, body > header .my-egov>ul>li.has-child").mouseenter(function(e) {
    if ($(window).innerWidth()>990) {
      var ul=$(this).find(">ul");
      var ulTop=ul.css("top").replace("px", "")*1;
      var offsetBottom=ul.offset().top+ul.innerHeight();
      var winBottom=$(window).innerHeight()+$(window).scrollTop();
      console.log(winBottom, offsetBottom, ulTop);
      var ulTop=Math.min(-10, winBottom-offsetBottom+ulTop);
      ul.css("top", ulTop);
    } else {
      ul.css("top", "");
    }
  });
});

objectFitImages();
jQuery(function() {
  objectFitImages();
});

var beforeUnloadSkip=false;
jQuery(window).on("beforeunload", function(e) {
  if (beforeUnloadSkip)
    return beforeunloadSkip=false;
  var loadingBox=jQuery("<div />", {"class": "loading"});
  jQuery("body").append(loadingBox);
});
